import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserContext } from '../UserContext';
import axios from 'axios';
import BookCard from '../components/bookCard';
import favoriteBook from '../functions/favoriteBook';
import unfavoriteBook from '../functions/unfavoriteBook';
import handleDownload from '../functions/handleDownload';

const HomePage = () => {
    const [featuredBooks, setFeaturedBooks] = useState([]);
    const userId = localStorage.getItem('user_id');
    const navigate = useNavigate();

    const handleAuthorClick = (authorId) => {
        navigate(`/authors/${authorId}`);
    };

    useEffect(() => {
        axios.get(`https://bookstorepang-7d8cea80dfe1.herokuapp.com/api/books/featured/${userId}`)
            .then(response => {
                setFeaturedBooks(response.data);
            })
            .catch(error => console.error('Error fetching featured books:', error));
    }, []);

    return (
        <div className='w-full'>
            <section className="flex flex-col lg:flex-row w-full h-96 shadow-lg overflow-hidden">
                <div className="lg:w-1/2 bg-gradient-to-r from-gray-200 via-gray-100 to-transparent flex items-center p-8">
                    <div>
                        <h1 className="text-4xl lg:text-6xl text-gray-800 font-serif font-bold mb-4 text-center lg:text-left md:text-left whitespace-nowrap">ស្វាគមន៍មកកាន់ពិភពចំណេះដឹង</h1>
                        <p className="text-xl lg:text-2xl text-gray-700 text-center lg:text-left md:text-left">ត្រឹមតែ 1.99$ អានបានគ្រប់ </p>
                        <p className="text-lg lg:text-lg text-yellow-700 text-center lg:text-left md:text-left">នេះជា early access សៀវភៅនឹងដាក់ចូលថ្មីៗរហូត!</p>

                    </div>
                </div>
                <div className="lg:w-1/2 flex items-center justify-evenly h-full lg:px-0 px-2 ">                   
                    <img src="https://s26162.pcdn.co/wp-content/uploads/2020/01/Sin-Eater-by-Megan-Campisi.jpg" alt="Book Cover" className="h-48 lg:h-80 md:80 object-cover shadow-lg"/>
                    <img src="https://www.shutterstock.com/blog/wp-content/uploads/sites/5/2017/10/the-50-coolest-book-covers-47.jpg" alt="Book Cover" className="h-48 lg:h-80 md:80 object-cover shadow-lg"/>
                    <img src="https://grey.com.np/cdn/shop/products/ced0146258dd1eabdce9792d50103e6a.jpg?v=1669893034" alt="Book Cover" className="h-48 lg:h-80 md:80 object-cover shadow-lg"/>
                </div>
            </section>

            <section className="bg-opacity-90 bg-gray-100 p-6">
                <h2 className="text-3xl lg:text-4xl font-serif font-bold text-brown mb-4">Featured Books</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {featuredBooks.map(book => (
                        <BookCard
                            key={book.id}
                            book={book}
                            handleAuthorClick={handleAuthorClick}
                            handleDownload={handleDownload}
                            userId={userId}
                            favoriteBook={favoriteBook}
                            unfavoriteBook={unfavoriteBook}
                        />
                    ))}
                </div>
            </section>
        </div>
    );
};

export default HomePage;
