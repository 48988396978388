import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const userId = localStorage.getItem('user_id'); // Assuming the user's ID is stored in localStorage
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const handleSearch = (e) => {
        e.preventDefault();
        navigate(`/books?search=${encodeURIComponent(searchTerm)}`);
    };

    const clearSearch = (e) => {
        e.preventDefault();
        setSearchTerm('');
        navigate(`/books?search=`);
    };

    const handleLogout = () => {
        localStorage.removeItem('user_id');
        navigate('/');
    };

    return (
        <header className="bg-gradient-to-r from-amber-100 to-amber-200 text-black py-6 shadow-md">
            <div className="container mx-auto flex flex-col lg:flex-row md:flex-col sm:flex-col justify-between items-center">
                <a href='/' className="text-2xl font-bold mb-4 lg:mb-0 md:mb-4 sm:mb-4">Sommaire | សៀវភៅសង្ខេប</a>
                <div className='flex my-2'>
                    <form onSubmit={handleSearch} className="flex">
                        <input
                            type="text"
                            placeholder="Search Books..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="px-3 py-2 rounded-l-md w-64 md:w-80 sm:w-full text-gray-900 flex-1"
                        />
                        <button type="submit" className="bg-white text-blue-500 px-4">
                            Search
                        </button>
                    </form>
                    <button onClick={(e) => { clearSearch(e) }} className="bg-white text-blue-500 px-4 rounded-r-md">
                        Clear
                    </button>
                </div>
                <nav className="mt-4 lg:mt-0 md:mt-4 sm:mt-4">
                    <ul className="flex space-x-6">
                        <li><a href="/" className="hover:text-indigo-200 transition-colors">Home</a></li>
                        <li><a href="/books" className="hover:text-indigo-200 transition-colors">Books</a></li>
                        {userId ? <li><a href="/profile" className="hover:text-indigo-200 transition-colors">Profile</a></li> : <li><a href="/login" className="hover:text-indigo-200 transition-colors">Login</a></li>}
                        {!userId ? <li><a href="/payment" className="hover:text-indigo-200 transition-colors">ចុះឈ្មោះ</a></li> : <li><button className="hover:text-indigo-200 transition-colors" onClick={handleLogout}>Logout</button></li>}
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
