import axios from 'axios';

const unfavoriteBook = (e, userId, bookId) => {
    e.preventDefault();
    e.stopPropagation();
    axios.delete(`https://bookstorepang-7d8cea80dfe1.herokuapp.com/api/users/${userId}/unfavorite`, { data: { book_id: bookId } })
        .then(response => {
            console.log(response.data.message);
            // Handle the response, e.g., update the UI to reflect the book being unfavorited
            return true
        })
        .catch(error => {
            console.error('Error unfavoriting book:', error);
        });
};

export default unfavoriteBook;
