const handleDownload = (e, userId, bookId) => {
    e.preventDefault();   // Prevent default action
    e.stopPropagation();  // Stop event propagation
      
    if (!userId) {
        console.error('user_id not found in localStorage');
        return;
    }

    const requestBody = {
        user_id: userId,
        book_id: bookId,
    };

    fetch('https://bookstorepang-7d8cea80dfe1.herokuapp.com/api/history', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    })
    .then((response) => response.json())
    .then((data) => {
        if (data.downloadLink) {
            fetch(data.downloadLink)
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = data.downloadLink; // Set a filename or get it from the response
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                    console.log('Download started...');
                })
                .catch(error => console.error('Blob download error:', error));
        } else {
            console.error('Failed to initiate download');
        }
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export default handleDownload