import './App.css';
import RouteApp from './route';

function App() {
  
  return (
    <>
      <RouteApp/>
    </>
  );
}

export default App;
