import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
    hidePostalCode: true, // Add this option to hide the postal code field,
    disableLink: true
    

  },
};

function CardInput() {
  return (
    <div className='my-4 w'>
      <label className='block text-gray-700 text-sm font-bold mb-2'>
            Payment
      </label>
      <CardElement options={CARD_ELEMENT_OPTIONS} className='p-3 border border-gray-300 rounded focus:ring-2 focus:ring-blue-200' />
    </div>
  );
}

export default CardInput;
