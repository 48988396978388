import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import AdminPage from "./pages/AdminPage";
import { UserProvider } from "./UserContext";
import BooksPage from "./pages/BooksPage";
import AuthorPage from "./pages/AuthorPage";
import ProfilePage from "./pages/ProfilePage";
import Header from "./components/header";
import ArticlePage from "./pages/ArticlePage";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PaymentPage from "./pages/PaymentPage";

export default function RouteApp() {
    const user_id = localStorage.getItem('user_id');

    const stripePromise = loadStripe('pk_live_51OLT62JLL0D0HwgtVSHtXNOx9rFfaJv2YiuGHlwjAvqHLIoR26gpvRJhTQytbnBKN4vQA9yWjkH5kX7vomkNUQwL00EBtXhkxH');

    return(
        <Router>
        <Elements stripe={stripePromise}>

        <div className="flex flex-col">
            <Header/>
            <div className="flex flex-1 mb-24"> {/* Adjust 64px to your header's actual height */}
                    <Routes>
                        <Route path='/' element={<HomePage/>}/>
                        <Route path='/login' element={!user_id && <LoginPage/>}/>
                        <Route path='/admin' element={<AdminPage/>}/>
                        <Route path='/books' element={<BooksPage/>}/>
                        <Route path='/authors/:authorId' element={<AuthorPage />} />
                        <Route path='/profile' element={user_id && <ProfilePage/>}/>
                        <Route path='/article/:id' element={<ArticlePage />} />
                        <Route path='/payment' element={<PaymentPage/>}/>

                    </Routes>

            </div>
            <footer className="fixed bottom-0 w-full p-6 flex items-center bg-white">
                <p className='text-2xl font-bold'>ចំណេះដឹងជានិរន្ដន៍</p>
            </footer>
        </div>
        </Elements>
        </Router>

    )
}