// src/pages/AdminPage.js
import React, { useState } from 'react';
import axios from 'axios';

const AdminPage = () => {
    const [newUser, setNewUser] = useState({ username: '', password: '', email: '' });

    const handleChange = (e) => {
        setNewUser({ ...newUser, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Replace with your API endpoint
            const response = await axios.post('https://bookstorepang-7d8cea80dfe1.herokuapp.com/api/users/register', newUser);
            console.log(response.data);
            // Handle post submission logic (e.g., showing success message, clearing form)
        } catch (error) {
            console.error('Error adding user', error);
            // Handle errors here (e.g., showing error message)
        }
    };

    return (
        <div>
            <h1>Admin - Add New User</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Username:</label>
                    <input type="text" name="username" value={newUser.username} onChange={handleChange} required />
                </div>
                <div>
                    <label>Password:</label>
                    <input type="password" name="password" value={newUser.password} onChange={handleChange} required />
                </div>
                <div>
                    <label>Email:</label>
                    <input type="email" name="email" value={newUser.email} onChange={handleChange} required />
                </div>
                <button type="submit">Add User</button>
            </form>
        </div>
    );
};

export default AdminPage;
