import React, {useState} from 'react';
import axios from 'axios';
// stripe
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
// Util imports
import {makeStyles} from '@material-ui/core/styles';
import CardInput from '../components/cardInput';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
// Custom Components

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    margin: '35vh auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
  },
  div: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'space-between',
  },
  button: {
    margin: '2em auto 1em',
  },
});

function PaymentPage() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false); // Introduce loading state

  // State
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmitPay = async (event) => {
    if (!stripe || !elements || loading) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
    setLoading(true); // Set loading to true when payment process starts

    let res;
    try {
      res = await axios.post('https://bookstorepang-7d8cea80dfe1.herokuapp.com/api/payments/pay', {'email': email,
      email: email,         
      username: username,
      password: password,
      age: age,
      gender: 'male'});


    const clientSecret = res.data['client_secret'];

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: email,
        },
      },
    });

    if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        toast.error(`Payment failed: ${result.error}`);
    } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
            toast.success('Registration and payment successful!');

            const user_id = res.data.user_id
            localStorage.setItem('user_id', user_id);
            navigate('/');
        }
      }

    } catch (error) {
      return toast.error('There was an error registering, please try again or contact support!');
    } finally {
      setLoading(false); // Set loading back to false after payment process finishes
    }




  }

    // Add a function to handle gender selection
    const handleGenderChange = (event) => {
        setGender(event.target.value);
      };

  return (
    <div className='w-full lg:w-1/2 mx-auto mt-20 shadow-lg p-6 bg-white rounded-lg'>
      <ToastContainer />
      <h1 className="text-2xl font-bold text-center mb-6">Register <br></br><span className='text-gray-500 font-normal text-lg'>(1.99$ រៀនបានរហូត)</span></h1>
      {loading && (
        <div className="flex justify-center items-center">
          {/* You can replace this with your preferred loading indicator */}
          <div className="animate-spin rounded-full h-20 w-20 border-b-2 border-gray-900"></div>
        </div>
      )}

      <div className=''>
        <label htmlFor='username' className='block text-gray-700 text-sm font-bold mb-2'>
          Username
        </label>
        <input
          type='text'
          id='username'
          className='p-3 border border-gray-300 rounded w-full focus:outline-none mb-3'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder='Enter your username'
          required
          autoComplete="off"
        />
        <label htmlFor='email' className='block text-gray-700 text-sm font-bold mb-2'>
          Email
        </label>
        <input
          type='email'
          id='email'
          className='p-3 border border-gray-300 rounded w-full focus:outline-none mb-3'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Enter your email'
          required
          autoComplete="off"
        />
        <label htmlFor='password' className='block text-gray-700 text-sm font-bold mb-2'>
          Password
        </label>
        <input
          type='password'
          id='password'
          className='p-3 border border-gray-300 rounded w-full focus:outline-none mb-3'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder='Enter your password'
          required
          autoComplete="off"
        />
        <label htmlFor='age' className='block text-gray-700 text-sm font-bold mb-2'>
          Age
        </label>
        <input
          type='number'
          id='age'
          className='p-3 border border-gray-300 rounded w-full focus:outline-none mb-3'
          value={age}
          onChange={(e) => setAge(e.target.value)}
          placeholder='Enter your age'
          required
          autoComplete="off"/>
        {/* for gender */}
              {/* Gender Selection */}
        <div>
            <label className='block text-gray-700 text-sm font-bold mb-2'>
            Gender
            </label>
            <select
            value={gender}
            onChange={handleGenderChange}
            className='p-3 border border-gray-300 rounded w-full focus:outline-none'
            required>
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
            </select>
        </div>

      </div>

      <CardInput />
      <div>
        <button
          className='transition w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
          onClick={handleSubmitPay}>
          Register
        </button>
        <div className='w-full flex justify-center items-center mt-2 '>
          <a
            className='transition hover:text-gray-400 text-gray-500'
            href="https://t.me/uddompanglearning"
            target='_blank'
            >
            Contant Support
          </a>
        </div>
      </div>
    </div>
  );
}

export default PaymentPage;