import React, { useState, useEffect } from 'react';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const BookCard = ({ book, handleAuthorClick, handleDownload, userId, favoriteBook, unfavoriteBook }) => {
    const [isFavorited, setIsFavorited] = useState(book.isFavorited);
    const navigate = useNavigate();

    const handleFavoriteClick = (e) => {
        e.stopPropagation();
        if (isFavorited) {
            unfavoriteBook(e, userId, book.id);
            toast.warning('Removed from favorites');
        } else {
            favoriteBook(e, userId, book.id);
            toast.success('Added to favorites');
        }
        setIsFavorited(!isFavorited);
    };

    const notify = (text) => toast.warning(text);

    const handleDownloadClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/article/${book.id}`); // Navigate to the article page with bookId as a query parameter
      };

    useEffect(() => {
        return () => {
            toast.dismiss(); // Dismiss any pending toasts when the component unmounts
        };
    }, []);
    

    return (
        <div className="max-w-xs mx-auto bg-white rounded-lg overflow-hidden shadow-sm">
            <ToastContainer />

            <img src={book.cover_image_url} alt={book.title} className="h-96 w-full object-contain p-4 pt-0" />
            <div className="p-4">
                <h3 className="text-lg text-gray-800 font-semibold truncate">{book.title}</h3>
                <p className="text-gray-600 text-sm mt-1">
                    <span className='font-bold'>Author:</span> {book.author}
                </p>
                <p className="text-gray-600 text-sm"><span className='font-bold'>Category:</span> {book.category}</p>
                <p className="text-gray-600 text-sm mb-4"><span className='font-bold'>Published:</span> {book.year}</p>
                {userId ?
                    <div className="flex items-center justify-between">
                        <button className="text-sm bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded" onClick={handleDownloadClick}>
                            Read
                        </button>
                        <button onClick={(e) => handleFavoriteClick(e)}>
                            {isFavorited ? <FaHeart className="text-red-500 text-xl" /> : <FaRegHeart className="text-red-500 text-xl" />}
                        </button>
                    </div> :
                    <div className="flex items-center justify-between">
                        <button className="text-sm bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded" onClick={() => notify('Please login to read books')}>
                            Read
                        </button>
                        <button onClick={() => notify('Please login to favorite books')}>
                            {isFavorited ? <FaHeart className="text-red-500 text-xl" /> : <FaRegHeart className="text-red-500 text-xl" />}
                        </button>
                    </div>
                }
            </div>
        </div>
    );
};

export default BookCard;
