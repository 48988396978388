import axios from 'axios';

const favoriteBook = (e, userId, bookId) => {
    e.preventDefault();
    e.stopPropagation();
    axios.post(`https://bookstorepang-7d8cea80dfe1.herokuapp.com/api/users/${userId}/favorite`, { book_id: bookId })
        .then(response => {
            return true;
        })
        .catch(error => {
            if (error.response && error.response.status === 409) {
                console.error('Book already favorited');
            } else {
                console.error('Error favoriting book:', error.message);
            }
        });
};

export default favoriteBook;
