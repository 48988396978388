import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ArticlePage = () => {
  const [downloadLink, setDownloadLink] = useState('');

  const bookId = useParams().id;
  const userId = localStorage.getItem('user_id'); // Assuming the user's ID is stored in localStorage
  
  const fetchArticle = async () => { 

    if (bookId) {
      // Send a request to your backend API to fetch the download link
      axios.post('https://bookstorepang-7d8cea80dfe1.herokuapp.com/api/history', { user_id: userId, book_id: bookId })
        .then(response => {
          const { downloadLink } = response.data;
          if (downloadLink) {
            setDownloadLink(downloadLink);
          } else {
            // Handle case where download link is not found for the specified book
            console.error('Download link not found for the specified book.');
          }
        })
        .catch(error => {
          // Handle error
          console.error('Error fetching download link:', error);
        });
    }
  }
  
  useEffect(() => {
    fetchArticle();
  }, []);

  useEffect(() => {
    // Use the 'id' parameter to fetch article data
    console.log('Article ID:', bookId);
    // Fetch article data based on the 'id' parameter
  }, [bookId]); // Ensure useEffect triggers when 'id' changes

  return (
    <div className="flex justify-center items-center w-full h-screen overflow-hidden">
      {downloadLink ? (
        <iframe
          title="Article Frame"
          src={downloadLink}
          className="w-full h-full border-none"
          allowFullScreen
        ></iframe>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ArticlePage;
