import React from 'react';

const BookBanner = () => {
    return (
        <div className="bg-amber-100 border-b-4 w-full border-amber-300 text-brown">
            <div className="container mx-auto py-8 px-4 text-center">
                <h1 className="text-4xl font-serif font-bold mb-2">ស្វែងរកពិភពនៃចំណេះដឹង</h1>
                <p className="text-xl font-light">ត្រឹមតែ 1.99$ អានបានគ្រប់</p>
            </div>
        </div>
    );
};

export default BookBanner;
