import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const AuthorPage = () => {
  const [books, setBooks] = useState([]);
  const { authorId } = useParams();

  useEffect(() => {
    axios.get(`https://bookstorepang-7d8cea80dfe1.herokuapp.com/api/authors/byAuthor/${authorId}`)
      .then(response => {
        setBooks(response.data);
      })
      .catch(error => {
        console.error('Error fetching books', error);
      });
  }, [authorId]);  // Dependency array ensures this runs when authorId changes

  return (
    <div>
      <h1>Books by Author</h1>
      <ul>
        {books.map(book => (
        <div key={book.id}>
          <li>{book.title}</li>
          <li>{book.description}</li>
          <li>{book.published_date}</li>
          <li>{book.authorName}</li>

          <img src={book.cover_image_url}></img>
        </div>
        ))}
      </ul>
    </div>
  );
};

export default AuthorPage;
