import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Don't forget to import CSS

const ProfileCard = ({ information, onChangePassword }) => {
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const resetForm = () => {
        setNewPassword('');
        setConfirmPassword('');
        setShowChangePassword(false); // Hide form on success
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            toast.error("New passwords do not match!");
            return;
        }
        if (onChangePassword && typeof onChangePassword === 'function') {
            const changePasswordPromise = onChangePassword(newPassword);
            if (changePasswordPromise && changePasswordPromise.then) {
                changePasswordPromise
                    .then(() => {
                        toast.success("Password changed successfully!");
                        resetForm();
                    })
                    .catch((error) => {
                        toast.error(error.response?.data?.message || "An error occurred!");
                    });
            } else {
                console.error('onChangePassword must return a promise.');
            }
        } else {
            console.error('onChangePassword is not provided or not a function');
        }
    };

    return (
        <div className="max-w-full mx-auto lg:max-w-xl  md:max-w-lg sm:max-w-md bg-white rounded-lg shadow-md overflow-hidden">
            <ToastContainer />
            <div className="p-5">
                <h2 className="text-xl font-semibold text-gray-800 mb-3">Profile</h2>
                <p className="text-gray-600"><span className='font-bold'>Username:</span> {information.username}</p>
                <p className="text-gray-600 mb-4 whitespace-nowrap"><span className='font-bold'>Email:</span> {information.email}</p>

                {!showChangePassword && (
                    <button
                        onClick={() => setShowChangePassword(!showChangePassword)}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded w-full mb-3"
                    >
                        Change Password
                    </button>
                )}

                {showChangePassword && (
                    <form onSubmit={handleSubmit} className="space-y-3">
                        <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-200"
                        />
                        <input
                            type="password"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-200"
                        />
                        <div className="flex justify-end space-x-3">
                            <button
                                type="button"
                                onClick={resetForm}
                                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                            >
                                Submit Change
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default ProfileCard;
