import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import unfavoriteBook from '../functions/unfavoriteBook';
import handleDownload from '../functions/handleDownload';
import ProfileCard from '../components/profileCard';

const ProfilePage = () => {
    const [downloadHistory, setDownloadHistory] = useState([]);
    const [favoriteBooks, setFavoriteBooks] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const userId = localStorage.getItem('user_id'); // Assuming the user's ID is stored in localStorage

    useEffect(() => {
        axios.get(`https://bookstorepang-7d8cea80dfe1.herokuapp.com/api/users/${userId}/favorites`)
            .then(response => setFavoriteBooks(response.data))
            .catch(error => console.error('Error fetching favorite books:', error));

        axios.get(`https://bookstorepang-7d8cea80dfe1.herokuapp.com/api/users/${userId}/history`)
            .then((response) => {
                setDownloadHistory(response.data);
            })
            .catch((error) => {
                console.error('Error fetching download history:', error);
            });
        
        axios.get(`https://bookstorepang-7d8cea80dfe1.herokuapp.com/api/users/${userId}`)
            .then((response) => {
                setUserInfo(response.data);
            })
    }, [userId]);

    const handleChangePassword = (newPassword) => {
        const userId = localStorage.getItem('user_id'); // Get the user's ID
    
        axios.put(`https://bookstorepang-7d8cea80dfe1.herokuapp.com/api/users/${userId}/password`, { newPassword })
            .then(response => {
                alert('Password successfully updated!');
            })
            .catch(error => {
                console.error('Error changing password:', error);
                alert('Failed to change password!');
            });
    };

    return (
        <div className="container mx-auto p-6">
            <section className="mb-12 flex flex-col lg:flex-row md:flex-row">
                <div className='mr-0 lg:mr-8 md:mr-8 '>
                <ProfileCard 
                    information={userInfo} 
                    onChangePassword={handleChangePassword} 
                />
                </div>
                <div className='mt-4'>
                <h2 className="text-2xl font-semibold mb-2 lg:mb-4 md:mb-4">My Favorite Books</h2>
                {favoriteBooks.length !== 0 ? (
                <ul className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 ">
                    {favoriteBooks.map((book) => (
                        <li key={book.id} className="bg-white p-2 rounded-lg transition-transform transform hover:scale-105">
                            <div className="relative">
                            <img src={book.cover_image_url} alt={book.title} className="h-96 w-full object-cover rounded-t-lg" />
                            <button
                                onClick={(e) => unfavoriteBook(e,   userId, book.bookId)}
                                className="absolute top-2 right-2 text-red-500 hover:text-red-700 transition-transform transform hover:scale-110"
                            >
                                <AiFillHeart size={28} />
                            </button>

                            </div>
                            <div className='p-2'>
                                <p className="text-lg font-semibold">{book.title}</p>
                                <p className="text-gray-600">Author: {book.author}</p>
                                <button className='p-1 bg-blue-500 hover:bg-blue-600 text-white font-bold transition rounded text-sm mt-2' onClick={(e) => handleDownload(e, userId, book.bookId)}>Read</button>
                            </div>
                        </li>
                    ))}
                </ul>
                ) : (
                    <div>
                        <p className='text-lg text-gray-400'>Add books to favorites now!</p>
                    </div>
                )}
                </div>
            </section>
            <section>
                <h2 className="text-2xl font-semibold mb-4">My History</h2>
                {downloadHistory.length !== 0 ? (
                <div className="flex overflow-x-auto space-x-4 pb-4">
                    {downloadHistory.map((download) => (
                        <div key={download.id} className="bg-white rounded-lg shadow-lg transform hover:scale-105 transition-transform overflow-x-hidden w-48  flex flex-col">
                            <img src={download.cover_image_url} alt={download.title} className="h-48 object-cover rounded-t-lg" />
                            <div className="p-4">
                                <p className="text-lg font-semibold whitespace-nowrap">{download.title}</p>
                                <p className="text-gray-600">Read on: {new Date(download.download_date).toLocaleDateString()}</p>
                                <button className='p-1 bg-blue-500 hover:bg-blue-600 text-white font-bold transition rounded text-sm mt-2' onClick={(e) => handleDownload(e, userId, download.book_id)}>Read</button>
                            </div>
                        </div>
                    ))}
                </div>
                ) : (
                    <div>
                        <p className='text-lg text-gray-400'>Start reading now!</p>
                    </div>
                )}
            </section>
        </div>
    );
};

export default ProfilePage;
