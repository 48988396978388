import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginPage = () => {
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const navigate = useNavigate();

    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://bookstorepang-7d8cea80dfe1.herokuapp.com/api/users/login', credentials);

            if (response.status === 200) {
                const user_id = response.data.user.id;
                localStorage.setItem('user_id', user_id);
                navigate('/');
            } else {
                console.error('Login error - Unexpected response status:', response.status);
            }
        } catch (error) {
            console.error('Login error', error.response);
            // Display error message using react-toastify
            toast.error(error.response?.data?.message || 'An error occurred!');
        }
    };

    return (
        <div className="items-center flex justify-center w-full mt-32">
            <ToastContainer /> {/* ToastContainer for react-toastify */}
            <div className="container mx-auto p-6 max-w-sm bg-white shadow-md rounded-lg">
                <h1 className="text-2xl font-bold text-center mb-6">Login</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block mb-2 font-medium text-gray-700">Username:</label>
                        <input
                            type="text"
                            name="username"
                            value={credentials.username}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                    </div>
                    <div>
                        <label className="block mb-2 font-medium text-gray-700">Password:</label>
                        <input
                            type="password"
                            name="password"
                            value={credentials.password}
                            onChange={handleChange}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                    </div>
                    <button type="submit" className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded-md transition-colors">
                        Login
                    </button>
                </form>
                <div className='flex justify-center items-center'>
                    <a href='/payment' className='flex justify-center items-center w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 rounded-md transition-colors mt-2'>Register</a>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
