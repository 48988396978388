import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import favoriteBook from '../functions/favoriteBook';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import unfavoriteBook from '../functions/unfavoriteBook';
import BookCard from '../components/bookCard';
import handleDownload from '../functions/handleDownload';
import { useSearchParams } from 'react-router-dom';
import BookBanner from '../components/bookBanner';

const BooksPage = () => {
  const [books, setBooks] = useState([]);
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    // Fetch categories
    axios.get('https://bookstorepang-7d8cea80dfe1.herokuapp.com/api/categories')
        .then(response => setCategories(response.data))
        .catch(error => console.error('Error fetching categories:', error));

    fetchBooks();
}, []);


  const handleAuthorClick = (authorId) => {
    navigate(`/authors/${authorId}`);
  };

  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('search');

  const fetchBooks = () => {
    const params = new URLSearchParams();
    if (searchQuery) params.append('search', searchQuery);
    if (selectedCategory) params.append('category', selectedCategory);

    axios.get(`https://bookstorepang-7d8cea80dfe1.herokuapp.com/api/books/${userId}?${params.toString()}`)
        .then(response => setBooks(response.data))
        .catch(error => console.error('Error fetching books:', error));
    };

    // Adjusted fetchBooks to include category filter
    useEffect(() => {

        fetchBooks();
    }, [searchQuery, selectedCategory]);

  
  const userId = localStorage.getItem('user_id');

    return (
      <div className="container mx-auto p-6">
          <BookBanner/>
          <div className='flex  p-6 lg:flex-row md:flex-col sm:flex-col flex-col'>
            <div className="mr-4 ">
                <div className='bg-white shadow p-4 shadow-xl'>
                    <label className="block mb-2 font-medium text-gray-700">Filter:</label>
                    <select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        className="p-2 border border-gray-300 rounded lg:w-64 md:w-64 sm:w-full w-full"
                    >
                <option value="">All Categories</option>
                {categories.map(category => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                ))}
            </select>
                </div>
            </div>
            <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8 lg:mt-0 ">
                {books.map((book) => (
                    <BookCard
                        key={book.id}
                        book={book}
                        handleAuthorClick={handleAuthorClick}
                        handleDownload={(e) => handleDownload(e, userId, book.id)}
                        userId={userId}
                        favoriteBook={favoriteBook}
                        unfavoriteBook={unfavoriteBook}
                    />
                ))}
            </ul>
          </div>
      </div>
    );
};

export default BooksPage;
